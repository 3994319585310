import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import Blockquote from "@components/blockquote"

import { VideoPlayer } from "@components/video"

import {
  ScrollToButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const CADM179ReviewPage = () => {
  return (
    <PageLayout>
      <SEO
        title="CAD M179 Microphone Demo | Recording Equipment | Jimmy Ether"
        ogDescription="Demonstration and review of the inexpensive CAD M179 Microphone with stereo examples used as a Blumlein pair."
        image="https://jimmyether.com/images/CADM179.jpg"
        url="https://jimmyether.com/cad-m179-review/"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>CAD M179 Microphone Demo</h1>
            <p>
              Watch in HD with a good set of headphones or studio monitors to get the full effect of the recording. Youtube really mangles the audio below 720p!
            </p>
            <ScrollToButton
              id="video"
              content="Watch Video"
              style={{ marginBottom: "20px" }}
            />
            <p>
              In this video, I do a demonstration of the CAD M179 continuously variable polar pattern large diaphragm condenser microphone. First as a Blumlein pair on a drum and guitar combo recorded live in the same room. Then, showing an array of possible polar pattern combinations on acoustic and vocals. I’m really pretty blown away with these mics for the price. I can’t think of a single microphone I’ve purchased in 20 years for under $500 each with this much versatility and neutral frequency response. And I only paid $250 total for the pair on Amazon (though the price seems to vary).
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/CADM179.jpg" alt="CAD M179 Microphone" />
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <p>
              Everyone raves about these on drumkit toms. I haven’t tried that yet, but I’m interested in how they might stack up to my AEA R84 ribbon mics used with my modified/augmented Glyn Johns drum miking technique. I can’t imagine they’ll beat the AEAs in figure-8, but there may be times where I want a more delicate response that a cardioid or even omni can provide… especially with brushes or an otherwise quiet drum part.
            </p>
            <p>
              And as I mention in the video, the bass response of these M179 mics is pretty impressive. I’m looking forward to trying them out on a bass amp — possibly even as a stereo pair. Or likewise, they might make a good kick mic outside a solid resonante head.
            </p>
            <p>
              Basically, this is now one of several mics I’ll recommend to anyone starting out building a microphone collection for a home/project studio
            </p>
          </div>
        }
      />
      <div id="video">
          <VideoPlayer youtubeId='GKR0w5hfhC4' />
      </div>
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>Video Transcript</h2>
            <p>
              Hey this is Jimmy Ether, and today I'm going to be doing a review of the CAD M179 microphone. I just got a pair of them and we're gonna test them out. I don't normally do gear reviews but I got this little a pair of these little CAD M79's recently and for the money it's a great mic. The reason I'm posting this is because I think every little project studio should grab a pair.  These are indispensable, especially if you don't have a big mic collection. Because they're extremely versatile. 
            </p>
            <p>
              You have a pattern selection from omnidirectional all the way you probably can't see this but all the way to to figure-8. And one of my favorite stereo techniques is a Blumlein pair using figure-8 mics as a pair and put them at perpendicular axes.
            </p>
            <p>
              I paid around $125 each for these. As you'll see, I use them as a stereo pair about ten feet back on a session with drums and guitar. And I do little demo with the acoustic guitar and vocals showing you how each pattern varies.
            </p>
            <p>
              They are nicely constructed. The anodized metal looks great feels heavy duty. It's not cheaply done at all for a Chinese mic. It comes with a nice shock mount and a smaller clip if you're miking where you don't have enough room for the all the extra cradle mess. Both of these lock onto the bottom of the shaft so it's very sturdy. The mic is a little heavy so you do you want to have a good mic stand. And it comes with this ridiculously side oversized protective case as well. For the money I don't see how they are pulling it off at this price point. I can't think of a mic that I would recommend similarly for less than $800. I hope that they hold out over time and don't crap out like sometimes mics can do on the cheaper end of the spectrum. I think this one's going to be a good mic for the long haul.
            </p>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h4>Got a question? Or want to give me a piece of your mind? All good, fill out the form.</h4>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}

export default CADM179ReviewPage
